import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import {
  Typography,
  CustomButton,
  FilterPopup,
  SearchInput,
} from '../../../common/components';
import { fonts, colors } from '../../../utils';
import { FilterIcon } from '../../../assets/icons';
import {
  renderTableBody,
  renderTableHeader,
} from '../../../common/helpers/table/tableRendering';
import InvitationStateUserModal from '../modals/InvitationStateUserModal';
import FileUpload from '../../../common/components/FileUpload';
import {
  InvitationUser,
  InviteUsersPayload,
  AgenciesType,
} from '../../../types';
import { toast } from '../../../common/api/api.base';

interface IInvitationTableProps {
  users: InvitationUser[];
  loading: boolean;
  inviteFiltersDirectory: AgenciesType[];
  onGetParsedUsersFromFile: (data: FormData) => void;
  onToggleInviteUserListModal: (value: boolean) => void;
  inviteListModalOpen: boolean;
  invitationFilter: number;
  onChangeInvitaionFilter: (value: number) => void;
  onInviteUsersRequest: (data: InviteUsersPayload) => void;
  inviteAllLoading: boolean;
  onDeleteInvitedUser: (value: number) => void;
  invitationOrder: string;
  setInvitationOrder: (value: string) => void;
  invitedSearchQuery?: string;
  setInvitedSearchQuery: (value: string) => void;
  invitationAsc: boolean;
  setInvitationAsc: (value: boolean) => void;
}

export const InvitationTable: React.FC<IInvitationTableProps> = ({
  loading,
  users,
  inviteFiltersDirectory,
  onGetParsedUsersFromFile,
  inviteListModalOpen,
  onToggleInviteUserListModal,
  invitationFilter,
  onChangeInvitaionFilter,
  onInviteUsersRequest,
  inviteAllLoading,
  onDeleteInvitedUser,
  invitationOrder,
  setInvitationOrder,
  invitedSearchQuery,
  setInvitedSearchQuery,
  invitationAsc,
  setInvitationAsc,
}) => {
  const [csv, setCsv] = useState<File | null>(null);

  useEffect(() => {
    if (csv !== null) {
      if (
        csv.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        csv.name.split('.').pop() === 'csv'
      ) {
        const formData = new FormData();
        formData.append('UsersListFile', csv);
        onGetParsedUsersFromFile(formData);
        setCsv(null);
      } else {
        toast({
          title: 'This file format is not supported',
          status: 'error',
          duration: 5000,
        });
      }
    }
  }, [csv]);

  const setOrder = (value: string) => {
    if (invitationOrder !== value) {
      setInvitationOrder(value);
    } else {
      setInvitationOrder('');
    }
  };

  const getStatusColor = (value: number) => {
    switch (value) {
      case 3:
        return colors.green;
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        return colors.orange;
      default:
        return colors.gray;
    }
  };

  return (
    <Box>
      <Flex justify="space-between" align="center" p="25px 40px 40px">
        <Flex direction="row" alignItems="center">
          <Typography size={20} family={fonts.roboto}>
            Invitation
          </Typography>
        </Flex>
        <Flex>
          <SearchInput
            placeholder="Search..."
            value={invitedSearchQuery}
            onChange={(value) => setInvitedSearchQuery(value)}
          />
          <FilterPopup
            states={[
              { id: 0, name: 'All', code: '0' },
              ...inviteFiltersDirectory,
            ].map((elem) => ({ ...elem, code: elem.id.toString() }))}
            checkedItems={[invitationFilter]}
            setCheckedItems={(value) => {
              if (value?.[1]?.toString()) {
                onChangeInvitaionFilter(value[1]);
              }
            }}
            withoutSearch
            withoutDefaultAll
          >
            <Box w="270px" ml="4">
              <CustomButton
                variant="outline"
                color={colors.gray}
                isSmall
                leftIcon={<FilterIcon />}
                background="transparent"
              >
                {inviteFiltersDirectory.find(
                  (elem) => elem.id === invitationFilter
                )?.name || 'All'}
              </CustomButton>
            </Box>
          </FilterPopup>
          <Box w="120px" ml="4">
            <FileUpload
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={setCsv}
            >
              <CustomButton isSmall>Upload List</CustomButton>
            </FileUpload>
          </Box>
          <Box w="120px" ml="4">
            <CustomButton
              isSmall
              onClick={() =>
                onInviteUsersRequest({
                  filterId: invitationFilter,
                  userId: null,
                })
              }
              isLoading={inviteAllLoading}
              isDisabled={invitationFilter === 0 || invitationFilter === 3}
            >
              Invite
            </CustomButton>
          </Box>
        </Flex>
      </Flex>
      <Box p="0 20px" mb="50px">
        <Table colorScheme="blackAlpha" className="fixed">
          <Thead>
            <Tr bg="#EDF2F7">
              {renderTableHeader(colors.gray, 'First Name', () =>
                setOrder('FirstName')
              )}
              {renderTableHeader(colors.gray, 'Last Name', () =>
                setOrder('LastName')
              )}
              {renderTableHeader(colors.gray, 'Email')}
              {renderTableHeader(colors.gray, 'Phone Number')}
              {renderTableHeader(colors.gray, 'Status', () =>
                setInvitationAsc(!invitationAsc)
              )}
              <Th isNumeric textTransform="capitalize">
                <Typography size={16} family={fonts.roboto} color={colors.gray}>
                  Actions
                </Typography>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Tr pos="relative" h="200px">
                <Td
                  border="0"
                  pos="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                >
                  <Spinner size="xl" />
                </Td>
              </Tr>
            ) : users.length !== 0 ? (
              users.map((elem) => (
                <Tr key={elem.id}>
                  {renderTableBody(colors.gray, elem.firstName)}
                  {renderTableBody(colors.gray, elem.lastName)}
                  {renderTableBody(colors.gray, elem.email)}
                  {renderTableBody(colors.gray, elem.phoneNumber)}
                  {renderTableBody(
                    getStatusColor(elem.statusId),
                    inviteFiltersDirectory.find(
                      (item) => item.id === elem.statusId
                    )?.name || ''
                  )}
                  <Td isNumeric>
                    {elem.statusId !== 3 ? (
                      <HStack spacing="20px" justify="flex-end">
                        <Text
                          fontSize="16px"
                          as="button"
                          type="button"
                          fontFamily={fonts.roboto}
                          color={colors.gray}
                          textDecoration="underline"
                          onClick={() =>
                            onInviteUsersRequest({
                              userId: elem.id,
                              filterId: elem.statusId,
                            })
                          }
                        >
                          Invite
                        </Text>
                        <Text
                          fontSize="16px"
                          as="button"
                          type="button"
                          fontFamily={fonts.roboto}
                          color={colors.gray}
                          textDecoration="underline"
                          onClick={() => onDeleteInvitedUser(elem.id)}
                        >
                          Delete
                        </Text>
                      </HStack>
                    ) : null}
                  </Td>
                </Tr>
              ))
            ) : null}
          </Tbody>
        </Table>
      </Box>
      <InvitationStateUserModal
        isOpen={inviteListModalOpen}
        onClose={() => onToggleInviteUserListModal(false)}
      />
    </Box>
  );
};
