import { all } from 'redux-saga/effects';
import loginSaga from './auth-sagas/login.saga';
import watchLogoutSaga from './auth-sagas/logout.saga';
import watchGetMembersSaga from './members-sagas/getMembers.saga';
import watchGetIdLicenseSaga from './licenses-sagas/getIdLicense.saga';
import watchDeleteMemberSaga from './members-sagas/deleteMember.saga';
import watchSuspendMemberSaga from './licenses-sagas/suspendMember.saga';
import watchConfirmIdLicenseSaga from './licenses-sagas/confirmIdLicense.saga';
import watchGetMemberLicensesSaga from './licenses-sagas/getMemberLicenses.saga';
import watchGetMemberGeneralInfoSaga from './licenses-sagas/getMemberGeneralInfo.saga';
import watchDeleteIdLicenseSaga from './licenses-sagas/deleteIdLicense.saga';
import watchResetPasswordSaga from './licenses-sagas/resetPassword.saga';
import watchVerifyMemberLicensesSaga from './licenses-sagas/verifyMemberLicenses.saga';
import watchDeleteMemberLicensesSaga from './licenses-sagas/deleteMemberLicenses.saga';
import watchGetSuspendedMembersSaga from './members-sagas/getSuspendedMembers.saga';
import watchGetStatesSaga from './auxiliary-sagas/getStates.saga';
import watchGetAgenciesSaga from './auxiliary-sagas/getAgencies.saga';
import watchSetRealEstatePhotoSaga from './licenses-sagas/setRealEstatePhoto.saga';
import watchSetIdLicensePhotoSaga from './licenses-sagas/setIdLicensePhoto.saga';
import watchGetAgenciesWithEmailsSaga from './agency-sagas/getAgenciesWithEmails.saga';
import watchGetReportsSaga from './reports-sagas/getReports.saga';
import watchGetReportDetailsSaga from './reports-sagas/getReportDetails.saga';
import watchDeleteReportSaga from './reports-sagas/deleteReport.saga';
import watchGetAgencySaga from './agency-sagas/getAgency.saga';
import watchGetAgenciesForPartnershipSaga from './agency-sagas/getAgenciesForPartnership.saga';
import watchAddPartnerToAgencySaga from './agency-sagas/addPartnerToAgency.saga';
import watchRemovePartnerFromAgencySaga from './agency-sagas/removePartnerToAgency.saga';
import watchSuspendReportSaga from './reports-sagas/suspendReport.saga';
import watchIgnoreReportSaga from './reports-sagas/ignoreReport.saga';
import watchEditAgencyEmailSaga from './agency-sagas/editAgencyEmail.saga';
import watchSaveIdLicenseSaga from './licenses-sagas/saveIdLicense.saga';
import watchGetArchivedMembersSaga from './members-sagas/getArchivedMembers.saga';
import watchArchiveMemberSaga from './members-sagas/archiveMember.saga';
import watchUnArchiveMemberSaga from './members-sagas/unArchiveMember.saga';
import watchEditAgencyDetailsSaga from './agency-sagas/editAgencyDetails.saga';
import watchAddNewAgencySaga from './agency-sagas/addNewAgency.saga';
import watchChangeAgencyLogoSaga from './agency-sagas/changeAgencyLogo.saga';
import watchUserViewedStatusSaga from './members-sagas/set-user-viewed-status.saga';
import watchSaveRELicenseInfoSaga from './licenses-sagas/saveRealEstateLicenseInfo.saga';

import watchGetStatesByAgencySaga from './auxiliary-sagas/getStatesByAgency.saga';

import watchUploadCSVSaga from './agency-sagas/uploadCSV.saga';
import watchGetUploadedUsersSaga from './agency-sagas/getUploadedUsers.saga';
import watchSaveAgencyStatesSaga from './agency-sagas/saveAgencyStates.saga';
import watchGetInvitesSaga from './agency-sagas/invites.saga';
import watchConfirmMlsIdSaga from './licenses-sagas/confirm-mls-id.saga';
import watchEditPartnerAgencyChoicesSaga from './agency-sagas/editParterAgencyChoices.saga';
import watchGetInviteFilterDirectorySaga from './auxiliary-sagas/getInviteFilter.directory.saga';
import workerDeleteInvitedUserSaga from './invitation-sagas/deleteInvitedUser.saga';
import workerGetInvitationsUsersSaga from './invitation-sagas/getInvitationUsers.saga';
import workerGetParsedUsersFromFileSaga from './invitation-sagas/getParsedUsersFromFile.saga';
import workerInviteUsersSaga from './invitation-sagas/inviteUsers.saga';
import workerUploadNewUsersSaga from './invitation-sagas/uploadNewUsers.saga';
import watchGetImportInfoSaga from './import-saga/import-info.saga';
import watchEditOfficeSaga from './offices-sagas/edit-office.saga';
import workerGetOffices from './offices-sagas/get-offices.saga';
import watchAddNewAgencyCompanySaga from './agency-sagas/addNewAgencyCompany.saga';
import watchGetCompanies from './agency-sagas/getCompanies.saga';
import watchDeleteOfficeSaga from './offices-sagas/delete-office.saga';
import watchAddOfficeSaga from './offices-sagas/add-office.sage';
import { watchGetPostTagsDirectorySaga } from './directories-sagas/get-post-tags.saga';
import watchCreateAdvertismentPostSaga from './advertisment-sagas/createAdvertismentPost.saga';
import watchDeleteAdvertismentPostSaga from './advertisment-sagas/deleteAdvertismentPost.saga';
import watchDeleteAdvertismentPostForAgencySaga from './advertisment-sagas/deleteAdvertismentPostForAgency.saga';
import watchEditAdvertismentPostSaga from './advertisment-sagas/editAdvertismentPost.saga';
import watchEditAdvertismentPostForAgencySaga from './advertisment-sagas/editAdvertismentPostForAgency.saga';
import watchGetAdvertismentSaga from './advertisment-sagas/getAdvertisment.saga';
import watchGetAdvertismentForAgencySaga from './advertisment-sagas/getAdvertismentForAgency.saga';
import watchGetAdvertismentPostByIdSaga from './advertisment-sagas/getAdvertismentPostById.saga';
import watchUpdateUserProfile from './licenses-sagas/updateUserProfile.saga';
import watchDiscardLicenseSaga from './licenses-sagas/discardLicense.saga';

export default function* rootSaga() {
  yield all([
    loginSaga(),
    watchLogoutSaga(),
    watchGetMembersSaga(),
    watchGetIdLicenseSaga(),
    watchGetMemberLicensesSaga(),
    watchDeleteMemberSaga(),
    watchGetMemberGeneralInfoSaga(),
    watchSuspendMemberSaga(),
    watchConfirmIdLicenseSaga(),
    watchDeleteIdLicenseSaga(),
    watchResetPasswordSaga(),
    watchVerifyMemberLicensesSaga(),
    watchDeleteMemberLicensesSaga(),
    watchGetSuspendedMembersSaga(),
    watchGetStatesSaga(),
    watchGetAgenciesSaga(),
    watchGetPostTagsDirectorySaga(),
    watchSetRealEstatePhotoSaga(),
    watchSetIdLicensePhotoSaga(),
    watchGetAgenciesWithEmailsSaga(),
    watchGetReportsSaga(),
    watchGetReportDetailsSaga(),
    watchDeleteReportSaga(),
    watchGetAgencySaga(),
    watchGetAgenciesForPartnershipSaga(),
    watchAddPartnerToAgencySaga(),
    watchRemovePartnerFromAgencySaga(),
    watchSuspendReportSaga(),
    watchIgnoreReportSaga(),
    watchEditAgencyEmailSaga(),
    watchSaveIdLicenseSaga(),
    watchUpdateUserProfile(),
    watchGetArchivedMembersSaga(),
    watchArchiveMemberSaga(),
    watchUnArchiveMemberSaga(),
    watchEditAgencyDetailsSaga(),
    watchAddNewAgencySaga(),
    watchChangeAgencyLogoSaga(),
    watchUserViewedStatusSaga(),
    watchSaveRELicenseInfoSaga(),
    watchGetStatesByAgencySaga(),
    watchUploadCSVSaga(),
    watchGetUploadedUsersSaga(),
    watchSaveAgencyStatesSaga(),
    watchGetInvitesSaga(),
    watchConfirmMlsIdSaga(),
    watchEditPartnerAgencyChoicesSaga(),
    watchGetInviteFilterDirectorySaga(),
    workerDeleteInvitedUserSaga(),
    workerGetInvitationsUsersSaga(),
    workerGetParsedUsersFromFileSaga(),
    workerInviteUsersSaga(),
    workerUploadNewUsersSaga(),
    watchGetImportInfoSaga(),
    watchEditOfficeSaga(),
    workerGetOffices(),
    watchAddNewAgencyCompanySaga(),
    watchGetCompanies(),
    watchDeleteOfficeSaga(),
    watchAddOfficeSaga(),
    watchCreateAdvertismentPostSaga(),
    watchDeleteAdvertismentPostSaga(),
    watchDeleteAdvertismentPostForAgencySaga(),
    watchEditAdvertismentPostSaga(),
    watchEditAdvertismentPostForAgencySaga(),
    watchGetAdvertismentSaga(),
    watchGetAdvertismentForAgencySaga(),
    watchGetAdvertismentPostByIdSaga(),
    watchDiscardLicenseSaga(),
  ]);
}
