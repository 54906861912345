import React, { Dispatch, SetStateAction, useState } from 'react';
import { Badge, Box, Flex, Table, Tbody, Td, Thead, Tr, Spinner, Tooltip } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { colors, fonts } from '../../../utils';
import { CustomButton, FilterPopup, SearchInput, Typography } from '../../../common/components';
import { FilterIcon, TooltipIcon } from '../../../assets/icons';
import { renderTableBody, renderTableHeader } from '../../../common/helpers/table/tableRendering';
import { RootStore } from '../../../redux';
import {
  ZootProUsersType,
  ConfirmMlsIdPayload,
  SetUserViewedPayload,
  LicenseType,
} from '../../../types';
import { openModal, confirmMlsId, closeModal } from '../../../redux/actions/licenses.actions';
import {
  archiveMember,
  unArchiveMember,
  setUserViewedStatus,
} from '../../../redux/actions/members.actions';
import DashboardModal from '../modals/DashboardModal';
import DashboardDeleteModal from '../modals/DashboardDeleteModal';
import { SideTextEnum } from '../../../common/const/sideBar';
import { URL } from '../../../common/api/api.base';
import { getDuration } from '../../../common/helpers/date';
import { useLicense } from '../../../common/hooks/useLicense';
import { renderExpirationLable } from '../../../common/helpers/license/renderExpirationLable';

interface INewDashboardTableProps {
  title: SideTextEnum;
  tooltip: string;
  showBadge?: boolean;
  nameValue: string;
  setNameValue: (value: string) => void;
  orderType: boolean;
  setOrderField: Dispatch<SetStateAction<string | undefined>>;
  setOrderType: Dispatch<SetStateAction<boolean>>;
  checkedStates: number[];
  setCheckedStates: Dispatch<SetStateAction<Array<number>>>;
  setChangeTable?: Dispatch<SetStateAction<number>>;
}

export const NewDashboardTable: React.FC<INewDashboardTableProps> = ({
  title,
  tooltip,
  showBadge,
  nameValue,
  setNameValue,
  orderType,
  setOrderField,
  setOrderType,
  checkedStates,
  setCheckedStates,
  setChangeTable,
}) => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { users, loading: membersLoading } = useSelector((state: RootStore) => state.members);
  const { states } = useSelector((state: RootStore) => state.auxiliary);
  const { loading: confirmMlsLoading } = useSelector(
    (state: RootStore) => state.licenses.confirmMlsId,
  );
  const {
    isModalOpen,
    memberInfo,
    idLicenseInfo,
    isDriverLicense,
    loading: licensesLoading,
  } = useSelector((state: RootStore) => state.licenses);

  const { defineExpirationStatus } = useLicense();

  const handleOrderUsers = (field: string) => {
    setOrderField(field);
    setOrderType(!orderType);
  };

  const handleOpenUserModal = (id: number) => {
    setUserId(id);
    dispatch(openModal());
  };

  const handleOpenDeleteModal = (id: number) => {
    setUserId(id);
    setIsDeleteModalOpen(true);
  };

  const onConfirmMldId = (payload: ConfirmMlsIdPayload) => {
    dispatch(confirmMlsId(payload));
  };

  const onCloseModal = () => dispatch(closeModal());

  const onArchiveMember = (id: number) => {
    if(setChangeTable !== undefined){
      setChangeTable(Date.now());
    }
    dispatch(archiveMember(id));
  };

  const onUnArchiveMember = (id: number) => dispatch(unArchiveMember(id));

  const onSetUserViewed = (data: SetUserViewedPayload) => {
    dispatch(setUserViewedStatus(data));
  };

  const statusHeader = () => {
    switch (title) {
      case SideTextEnum.WorkMembers:
        return 'License Status';
      case SideTextEnum.UnVerifiedUsers:
        return '# of Days';
      case SideTextEnum.VerifiedUsers:
        return 'Pending Status';
      default:
        return 'RE License Expires';
    }
  };

  // If "New" - "new"
  // If no "New" and at least one "Expired" - "expired"
  // Otherwise - user is active
  const pendingApprovalsStatus = (licenses: LicenseType[]) => {
    const isNew = licenses.some(license => dayjs(license.expirationDate).year() === 1);

    if (isNew) return 'New';

    const isExpired = licenses.some(license => {
      const days = getDuration(
        dayjs().format('YYYY-MM-DD'),
        dayjs(license.expirationDate).format('YYYY-MM-DD'),
      );
      const duration = Math.ceil(days);

      return duration < 0;
    });

    if (isExpired) return 'Expired';

    return 'New';
  };

  return (
    <>
      <Flex justify="space-between" align="center" p="25px 40px 40px">
        <Box d="flex" flexDir="row" alignItems="center">
          <Typography size={20} family={fonts.roboto}>
            {title}
            {showBadge && (
              <Badge
                mt="-10px"
                bg={colors.active}
                color="#fff"
                textTransform="capitalize"
                fontWeight={400}
                fontFamily={fonts.roboto}
                borderRadius="4px"
              >
                New items
              </Badge>
            )}
          </Typography>
          <Box ml="5px">
            <Tooltip
              shouldWrapChildren
              hasArrow
              textColor="#888DAB"
              fontSize="14px"
              fontWeight="400"
              label={tooltip}
              placement="bottom-start"
              bg="white"
              color="black"
              mt="1vh"
              p="25px"
              borderRadius="10px"
            >
              <TooltipIcon />
            </Tooltip>
          </Box>
        </Box>

        <Flex>
          <FilterPopup
            states={states}
            checkedItems={checkedStates}
            setCheckedItems={setCheckedStates}
          >
            <Box w="270px" mr="4">
              <CustomButton
                variant="outline"
                color={colors.gray}
                isSmall
                leftIcon={<FilterIcon />}
                background="transparent"
              >
                State
              </CustomButton>
            </Box>
          </FilterPopup>
          <SearchInput placeholder="Search..." value={nameValue} onChange={setNameValue} />
        </Flex>
      </Flex>
      <Box p="0 20px" mb="50px">
        <Table colorScheme="blackAlpha" className="fixed">
          <Thead>
            <Tr bg="#EDF2F7">
              {renderTableHeader(colors.gray, 'Name', () => handleOrderUsers('name'))}
              {renderTableHeader(colors.gray, 'Email')}
              {renderTableHeader(colors.gray, 'Agencies', () => handleOrderUsers('agencies'))}
              {renderTableHeader(colors.gray, statusHeader(), () =>
                handleOrderUsers('license-exp'),
              )}
              {/* {renderTableHeader(colors.gray, 'Drivers License Expires', () => handleOrderUsers('idexp'))} */}
              {renderTableHeader(colors.gray, 'Actions')}
            </Tr>
          </Thead>
          <Tbody>
            {membersLoading ? (
              <Tr pos="relative" h="200px">
                <Td
                  border="0"
                  pos="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                >
                  <Spinner size="xl" />
                </Td>
              </Tr>
            ) : users.length !== 0 ? (
              users.map((user: ZootProUsersType) => {
                dayjs.extend(customParseFormat);
                const dateNow = dayjs().format('YYYY-MM-DD');

                // const idDate = dayjs(user.idLicenseExpDate);
                // const idDaysDiff = idDate.diff(dateNow, 'days');
                // const idLicenseDiff =
                //   idDaysDiff >= 90
                //     ? dayjs(idDate).format('MM/DD/YYYY')
                //     : idDaysDiff <= 0
                //     ? 'Expired'
                //     : `${idDaysDiff} Days`;

                // const localIdLicenseDate =
                //   user.idLicenseExpDate === '0001-01-01T00:00:00Z'
                //     ? ''
                //     : idLicenseDiff;

                const sortedReLicense =
                  user.licenses.length !== 0
                    ? [...user.licenses].sort((a, b) =>
                        a.expirationDate.localeCompare(b.expirationDate),
                      )
                    : [];

                const reLicenseDate =
                  sortedReLicense === null ? dayjs() : sortedReLicense[0]?.expirationDate;
                const reDate = dayjs(reLicenseDate).format('YYYY-MM-DD');
                const daysDiff = dayjs(reDate).diff(dayjs(dateNow), 'days');
                const reLicenseDiff =
                  daysDiff > 60 ? 'Active' : daysDiff <= 0 ? 'Expired' : `Exp. in ${daysDiff} Days`;

                const userRegDate = dayjs(user.registrationDate).format('YYYY-MM-DD');
                const daysFromRegistration = dayjs(dateNow).diff(dayjs(userRegDate), 'days');
                const pendingStatus = pendingApprovalsStatus(user.licenses);

                const labels = sortedReLicense
                  .map(license => {
                    const label = renderExpirationLable(license, title);

                    return label;
                  })
                  .filter(Boolean);

                return (
                  <Tr
                    key={user.id}
                    bg={
                      (title === SideTextEnum.VerifiedUsers && !user.isAsPendingApprovalViewed) ||
                      (title === SideTextEnum.UnVerifiedUsers && !user.isAsNewApplicationViewed)
                        ? 'rgba(79, 209, 197, .3)'
                        : 'none'
                    }
                  >
                    {renderTableBody(
                      colors.primary,
                      user.fullName,
                      user.avatar &&
                        `${URL}/api/storage/static-images/${user.avatar.itemHash}${user.avatar.itemExtension}`,
                    )}
                    {renderTableBody(colors.gray, user.email)}
                    {renderTableBody(
                      colors.gray,
                      user.agencies !== null ? user.agencies.map(ag => ag.name).join(', ') : '',
                    )}
                    {renderTableBody(
                      colors.gray,
                      title === SideTextEnum.UnVerifiedUsers
                        ? `${daysFromRegistration} days`
                        : labels,
                      undefined,
                      false,
                      true,

                      // : title === SideTextEnum.VerifiedUsers
                      // ? pendingStatus
                      // : sortedReLicense === null
                      // ? ''
                      // : sortedReLicense[0].expirationDate === '0001-01-01T00:00:00Z'
                      // ? ''
                      // : reLicenseDiff,
                    )}
                    {/* {renderTableBody(colors.gray, localIdLicenseDate)} */}
                    <Td w="10vw">
                      <Flex>
                        <CustomButton
                          mr="20px"
                          onClick={() => handleOpenUserModal(user.id)}
                          color={colors.gray}
                          isSmall
                          isFullWidth={false}
                          background="transparent"
                          variant="link"
                        >
                          Details
                        </CustomButton>
                        <CustomButton
                          onClick={() => handleOpenDeleteModal(user.id)}
                          color={colors.gray}
                          isSmall
                          isFullWidth={false}
                          background="transparent"
                          variant="link"
                        >
                          Archive
                        </CustomButton>
                      </Flex>
                    </Td>
                  </Tr>
                );
              })
            ) : null}
          </Tbody>
        </Table>
      </Box>
      {isModalOpen ? (
        <DashboardModal
          onConfirmMldId={onConfirmMldId}
          confirmMlsLoading={confirmMlsLoading}
          title={title}
          userId={userId}
          loading={licensesLoading}
          isModalOpen={isModalOpen}
          onCloseModal={onCloseModal}
          idLicenseInfo={idLicenseInfo}
          isDriverLicense={isDriverLicense}
          memberInfo={memberInfo}
          onUnArchiveMember={onUnArchiveMember}
          onSetUserViewed={onSetUserViewed}
        />
      ) : null}
      <DashboardDeleteModal
        title="Are you sure you want archive this member?"
        text="This member will deleted from Members"
        isOpen={isDeleteModalOpen}
        onClick={() => onArchiveMember(userId)}
        setIsModalOpen={setIsDeleteModalOpen}
      />
    </>
  );
};
