import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import { createStandaloneToast } from '@chakra-ui/react';
import {
  changeAgencyLogo,
  changeAgencyLogoError,
  changeAgencyLogoSuccess,
} from '../../actions/agency.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerChangeAgencyLogo(action: ReturnType<typeof changeAgencyLogo>) {
  const toast = createStandaloneToast();
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.patchFile, `${URL}/api/admin/agencies/${action.payload.id}`, action.payload.logo);
    yield console.log('changeAgencyLogo', action, result);
    if (result.success) {
      yield put(changeAgencyLogoSuccess(result));
      toast({
        title: 'The logo updated successfully',
        status: 'success',
        duration: 5000,
      });
    } else {
      yield put(changeAgencyLogoError(result.errors));
    }
  } catch (error) {
    yield put(changeAgencyLogoError(error));
    console.log(error);
  }
}

export default function* watchChangeAgencyLogoSaga():
Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(changeAgencyLogo.type, workerChangeAgencyLogo);
}
