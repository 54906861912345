import React from 'react';
import {
  Box,
  Accordion,
  Text,
  Flex,
  Divider,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { OfficeItem } from '../components';
import { colors, fonts } from '../../../../../utils';
import { SearchInput, Typography } from '../../../../../common/components';
import { Office, EditOfficePayload } from '../../../../../types';
import { DashboardPaginator } from '../../../components';

interface IOfficesTabProps {
  onEditOffice: (value: EditOfficePayload) => void;
  page: number;
  setPage: (value: number) => void;
  searchQuery: string;
  setQuery: (value: string) => void;
  loading: boolean;
  items: Office[];
  totalCount: number;
  editingLoading: boolean;
  officeId: number;
}

export const OfficesTab: React.FC<IOfficesTabProps> = ({
  onEditOffice,
  editingLoading,
  items,
  loading,
  officeId,
  page,
  searchQuery,
  setPage,
  setQuery,
  totalCount,
}) => (
  <Box>
    <Divider borderColor="#D1D2DB" opacity={1} />
    <Flex align="center" justify="space-between" my="15px">
      <Text color={colors.primary} fontFamily={fonts.roboto} fontSize="20px">
        Office Name
      </Text>
      <SearchInput
        placeholder="Search..."
        value={searchQuery}
        onChange={setQuery}
      />
    </Flex>
    {!loading ? (
      <>
        {items.length ? (
          <Accordion defaultIndex={[0]} allowMultiple>
            {items.map((elem) => (
              <OfficeItem
                key={elem.id}
                {...elem}
                onEditOffice={onEditOffice}
                editingLoading={editingLoading}
                editingLoadingId={officeId}
              />
            ))}
          </Accordion>
        ) : (
          <Center h="500px">
            <Typography size={14}>There is no result to display</Typography>
          </Center>
        )}
      </>
    ) : (
      <Center h="500px">
        <Spinner size="xl" />
      </Center>
    )}

    <DashboardPaginator
      currentPage={page}
      pageSize={50}
      usersTotalCount={totalCount}
      membersLoading={loading}
      setCurrentPage={setPage}
    />
  </Box>
);
