import React, { useState } from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Modal,
  IconButton,
  CloseButton,
  ModalBody,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Spinner,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { BackArrow } from '../../../assets/icons';
import { Typography, CustomButton } from '../../../common/components';
import { colors, fonts } from '../../../utils';
import {
  renderTableBody,
  renderTableHeader,
} from '../../../common/helpers/table/tableRendering';
import { RootStore } from '../../../redux';
import { ParsedInvitationUser } from '../../../types';
import { DashboardPaginator } from '../components';
import {
  deleteUserFromInviteList,
  uploadNewUsersRequest,
  setInvitationListOrder,
} from '../../../redux/actions/invitation.actions';

interface IInvitationStateUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PAGE_SIZE = 50;

const InvitationStateUserModal: React.FC<IInvitationStateUserModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const { items, loading, order } = useSelector(
    (state: RootStore) => state.invitation.inviteList
  );
  const uploadNewUsersLoading = useSelector(
    (state: RootStore) => state.invitation.uploadNewUsersLoading
  );

  const setOrder = (value: string) => {
    console.log(value, order, 'setOrder');
    if (value !== order) {
      dispatch(setInvitationListOrder(value));
    } else {
      dispatch(setInvitationListOrder(''));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent p="20px">
        <ModalHeader
          alignItems="center"
          d="flex"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <IconButton
              aria-label="Back to previous modal"
              icon={<BackArrow />}
              onClick={onClose}
              mr="30px"
              variant="unstyled"
            />
            <Typography weight={500} size={20}>
              User List ({items.length} users)
            </Typography>
          </Flex>
          <CloseButton color={colors.gray} onClick={onClose} />
        </ModalHeader>
        <ModalBody>
          <Table colorScheme="blackAlpha">
            <Thead>
              <Tr bg="#EDF2F7">
                {renderTableHeader(colors.gray, 'First Name', () =>
                  setOrder('firstName')
                )}
                {renderTableHeader(colors.gray, 'Last Name', () =>
                  setOrder('lastName')
                )}
                {renderTableHeader(colors.gray, 'Email')}
                {renderTableHeader(colors.gray, 'Phone Number')}
                <Th isNumeric textTransform="capitalize">
                  <Typography
                    size={16}
                    family={fonts.roboto}
                    color={colors.gray}
                  >
                    Action
                  </Typography>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr pos="relative" h="400px">
                  <Td
                    border="0"
                    pos="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                  >
                    <Spinner size="xl" />
                  </Td>
                </Tr>
              ) : items.length !== 0 ? (
                items
                  .slice((currentPage - 1) * PAGE_SIZE, PAGE_SIZE * currentPage)
                  .map((item: ParsedInvitationUser, index: number) => (
                    <Tr key={item.phoneNumber}>
                      {renderTableBody(colors.gray, item.firstName)}
                      {renderTableBody(colors.gray, item.lastName)}
                      {renderTableBody(colors.gray, item.email)}
                      {renderTableBody(colors.gray, item.phoneNumber)}
                      <Td isNumeric>
                        <CustomButton
                          width="auto"
                          onClick={() =>
                            dispatch(deleteUserFromInviteList(index))
                          }
                          background="white"
                          color={colors.primary}
                          fontFamily={fonts.roboto}
                          fontSize={16}
                          textDecoration="underline"
                          height="30px"
                        >
                          Delete
                        </CustomButton>
                      </Td>
                    </Tr>
                  ))
              ) : (
                items.length === 0 && (
                  <Tr pos="relative" h="400px">
                    <Td
                      border="0"
                      pos="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                    >
                      <Typography weight={500} size={20}>
                        List is Empty
                      </Typography>
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
          <DashboardPaginator
            pageSize={PAGE_SIZE}
            currentPage={currentPage}
            membersLoading={loading}
            usersTotalCount={items.length}
            setCurrentPage={setCurrentPage}
          />
          <Flex justify="space-between" mt="15px">
            <CustomButton
              background="transparent"
              variant="outline"
              color={colors.gray}
              w="45%"
              onClick={onClose}
            >
              Back
            </CustomButton>
            <CustomButton
              w="45%"
              onClick={() => dispatch(uploadNewUsersRequest(items))}
              isDisabled={!items.length}
              isLoading={uploadNewUsersLoading}
            >
              Upload
            </CustomButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InvitationStateUserModal;
