import {
  ForkEffect,
  put,
  call,
  takeEvery,
} from '@redux-saga/core/effects';
import ApiBase, { URL } from '../../../common/api/api.base';
import {
  saveRealEstateLicenseInfoError,
  saveRealEstateLicenseInfoRequest,
  saveRealEstateLicenseInfoSuccess,
} from '../../actions/licenses.actions';
import { workerGetMemberLicenses } from './getMemberLicenses.saga';
import { IResponseWithoutValue } from '../../../types';

function* workerSaveRELicenseInfo(action: ReturnType<typeof saveRealEstateLicenseInfoRequest>) {
  console.log(action.payload.licensePayload, 'licensePayload');
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.put, `${URL}/api/admin/save-real-estate-license-info`, action.payload.licensePayload);
    yield console.log('saveIdLicense', action, result);
    if (result.success) {
      yield put(saveRealEstateLicenseInfoSuccess(action.payload.licensePayload));
      yield workerGetMemberLicenses({
        payload: { UserId: action.payload.userId },
        type: 'GET_LICENSES',
      });
    } else {
      yield put(saveRealEstateLicenseInfoError(result.errors));
    }
  } catch (error) {
    yield put(saveRealEstateLicenseInfoError(error));
    console.log(error);
  }
}

export default function* watchSaveRELicenseInfoSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(saveRealEstateLicenseInfoRequest.type, workerSaveRELicenseInfo);
}
