import { takeEvery, ForkEffect, call, put } from 'redux-saga/effects';

import ApiBase, { URL } from '../../../common/api/api.base';
import {
  verifyMemberLicenses,
  verifyMemberLicensesError,
  verifyMemberLicensesSuccess,
} from '../../actions/licenses.actions';
import { IResponseWithoutValue } from '../../../types';

function* workerVerifyMemberLicenses(action: ReturnType<typeof verifyMemberLicenses>) {
  try {
    const result: IResponseWithoutValue = yield call(
      ApiBase.put,
      `${URL}/api/admin/confirm-real-estate-license`,
      action.payload.licensePayload,
    );
    yield console.log('verifyMemberLicenses', action, result);
    if (result.success) {
      yield put(verifyMemberLicensesSuccess());
    } else {
      yield put(verifyMemberLicensesError(result.errors));
    }
  } catch (error) {
    yield put(verifyMemberLicensesError(error));
    console.log(error);
  }
}

export default function* watchVerifyMemberLicensesSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeEvery(verifyMemberLicenses.type, workerVerifyMemberLicenses);
}
