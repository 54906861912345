import React from 'react';
import { Avatar, Flex, Td, Th, IconButton } from '@chakra-ui/react';

import { fonts } from '../../../utils';
import { Typography } from '../../components';
import { UpDownArrowIcon } from '../../../assets/icons';
import { TdContent } from '../license/components/TdContent';

export const renderTableHeader = (
  color: string,
  text: string,
  sortBy?: () => void,
): JSX.Element => (
  <Th maxW="200px" textTransform="capitalize" isNumeric>
    <Flex alignItems="center" textAlign="left">
      <Typography noOfLines={2} size={16} family={fonts.roboto} color={color} lineHeight="18px">
        {text}
      </Typography>
      {sortBy ? (
        <IconButton aria-label={`Filter table by ${text}`} onClick={sortBy} ml="10px">
          <UpDownArrowIcon />
        </IconButton>
      ) : null}
    </Flex>
  </Th>
);

export const renderTableBody = (
  color: string,
  text: string | number | string[],
  icon?: string,
  isTextCentered?: boolean,
  flexWrap?: boolean,
): JSX.Element => {
  return (
    <Td maxW="200px">
      <Flex
        align="center"
        justifyContent={isTextCentered ? 'center' : undefined}
        flexWrap={flexWrap ? 'wrap' : 'nowrap'}
      >
        {icon && <Avatar w="43px" h="43px" name={icon} src={icon} mr="30px" />}
        <TdContent content={text} color={color} />
      </Flex>
    </Td>
  );
};

export const renderDataHeader = (color: string, text: string, width: string): JSX.Element => (
  <Th minW="200px" w={width} textTransform="capitalize" isNumeric>
    <Flex alignItems="center" textAlign="left">
      <Typography noOfLines={2} size={16} family={fonts.roboto} color={color}>
        {text}
      </Typography>
    </Flex>
  </Th>
);

export const renderDataBody = (
  color: string,
  text: string | number,
  width: string,
  openInfo?: () => void,
): JSX.Element => (
  <Td minW="200px" w={width} onClick={openInfo}>
    <Flex align="center" as={openInfo ? 'button' : undefined} type="button">
      <Typography noOfLines={2} size={16} family={fonts.roboto} color={color}>
        {text}
      </Typography>
    </Flex>
  </Td>
);
