import React, { ReactElement, useEffect, useState } from 'react';
import {
  Checkbox,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/react';

import { colors } from '../../../utils';
import { Typography } from '../Typography';
import { SearchInput } from '../SearchInput';
import { StatesType } from '../../../types';

interface IFilterPopupProps {
  children: ReactElement;
  states: Array<StatesType>;
  checkedItems: Array<number>;
  setCheckedItems: (value: number[]) => void;
  withoutSearch?: boolean;
  withoutDefaultAll?: boolean;
  boxRef?: any;
  setStateNames?: (stateName: string[]) => void;
  stateNames?: string[];
  canChooseOnlyOne?: boolean;
}

const FilterPopup: React.FC<IFilterPopupProps> = ({
  states,
  children,
  checkedItems,
  setCheckedItems,
  withoutSearch = false,
  withoutDefaultAll = false,
  boxRef,
  setStateNames,
  stateNames,
  canChooseOnlyOne = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<
    { id: number; name: string }[]
  >([]);

  const initRef = React.useRef<any>();

  const allStates = states.map((state) => ({ id: state.id, name: state.name }));
  const filteredStates = searchResults.length !== 0 ? searchResults : allStates;

  const allChecked = checkedItems.length === filteredStates.length;

  useEffect(() => {
    const results = allStates.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, states]);

  const handleSelectState = (id: number, name: string) => {
    if (canChooseOnlyOne) {
      setCheckedItems(checkedItems[0] === id ? [] : [id]);
    } else if (!checkedItems.includes(id)) {
      setCheckedItems([...checkedItems, id]);
      setStateNames && stateNames && setStateNames([...stateNames, name]);
    } else {
      setCheckedItems(checkedItems.filter((itemId) => itemId !== id));
      setStateNames &&
        stateNames &&
        setStateNames(stateNames.filter((itemName) => itemName !== name));
    }
  };

  const handleSelectAllStates = (e: boolean) => {
    if (e) {
      setCheckedItems(filteredStates.map((state) => state.id));
    } else {
      setCheckedItems([]);
    }
  };

  return (
    <Popover
      placement="bottom-start"
      isLazy
      size="md"
      initialFocusRef={initRef}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        w={boxRef?.current?.offsetWidth}
        maxHeight="270px"
        border="none"
        boxShadow="7px 0px 30px rgba(102, 102, 102, 0.25)"
        p={1}
        overflow="auto"
      >
        <PopoverBody ref={initRef}>
          {!withoutSearch && (
            <SearchInput
              placeholder="Search"
              value={searchTerm}
              onChange={setSearchTerm}
            />
          )}
          {!withoutDefaultAll && !canChooseOnlyOne && (
            <Checkbox
              mt="15px"
              colorScheme="facebook"
              borderColor={colors.primary}
              isChecked={allChecked}
              onChange={(e) => handleSelectAllStates(e.target.checked)}
            >
              <Typography size={14}>Select all</Typography>
            </Checkbox>
          )}
          <Stack mt={1} spacing={1}>
            {filteredStates.map((box) => (
              <Checkbox
                key={box.id}
                colorScheme="facebook"
                borderColor={colors.primary}
                isChecked={checkedItems.includes(box.id)}
                onChange={() => handleSelectState(box.id, box.name)}
              >
                <Typography size={14}>{box.name}</Typography>
              </Checkbox>
            ))}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export { FilterPopup };
