import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '@chakra-ui/react';
import { InvitationTable, DashboardPaginator } from '../components';
import { getInvitedFilterDirectoryRequest } from '../../../redux/actions/auxiliary.actions';
import {
  getInvitationUsersRequest,
  getParsedUsersFromFileRequest,
  toggleInviteUserListModal,
  changeInvitaionFilter,
  inviteUsersRequest,
  deleteInvitedUserRequest,
} from '../../../redux/actions/invitation.actions';
import { RootStore } from '../../../redux';
import { InviteUsersPayload } from '../../../types';

const PAGE_SIZE = 50;

export const Invitation: React.FC = () => {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState<string | undefined>('');
  const [order, setOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [invitationAsc, setInvitationAsc] = useState(true);

  const {
    inviteListModalOpen,
    invitationFilter,
    inviteAllLoading,
    uploadNewUsersLoading,
  } = useSelector((state: RootStore) => state.invitation);
  const {
    items: invitedUsers,
    totalCount: invitedUsersTotalCount,
    loading: invitedUsersLoading,
  } = useSelector((state: RootStore) => state.invitation.allUsers);
  const inviteFiltersDirectory = useSelector(
    (state: RootStore) => state.auxiliary.inviteFiltersDirectory
  );
  const { errors: deleteInviteErrors, loading: deleteInviteLoading } =
    useSelector((state: RootStore) => state.invitation.deleteUser);
  const { errors: inviteUserErrors, loading: inviteUserLoading } = useSelector(
    (state: RootStore) => state.invitation.inviteUser
  );
  const { errors: inviteListErrors } = useSelector(
    (state: RootStore) => state.invitation.inviteList
  );

  const fetchInvitationUsers = () => {
    dispatch(
      getInvitationUsersRequest({
        Search: searchQuery || '',
        FilterId: invitationFilter,
        Order: order,
        PageNumber: currentPage,
        IsAsc: invitationAsc,
        PageSize: PAGE_SIZE,
      })
    );
  };

  const prevUploadNewUsersLoading = usePrevious(uploadNewUsersLoading);
  const prevInviteUserLoading = usePrevious(inviteUserLoading);
  const prevInviteAllLoading = usePrevious(inviteAllLoading);
  const prevDeleteInviteLoading = usePrevious(deleteInviteLoading);

  useEffect(() => {
    dispatch(getInvitedFilterDirectoryRequest());
    fetchInvitationUsers();
  }, [searchQuery, invitationFilter, order, currentPage, invitationAsc]);

  const onGetParsedUsersFromFile = (data: FormData) =>
    dispatch(getParsedUsersFromFileRequest(data));

  const onToggleInviteUserListModal = (value: boolean) =>
    dispatch(toggleInviteUserListModal(value));

  const onChangeInvitaionFilter = (data: number) =>
    dispatch(changeInvitaionFilter(data));

  const onInviteUsersRequest = (data: InviteUsersPayload) =>
    dispatch(inviteUsersRequest(data));

  const onDeleteInvitedUser = (data: number) =>
    dispatch(deleteInvitedUserRequest(data));

  useEffect(() => {
    if (prevUploadNewUsersLoading && inviteListErrors.length === 0) {
      setCurrentPage(1);
      fetchInvitationUsers();
    }
  }, [uploadNewUsersLoading]);

  useEffect(() => {
    if (
      prevInviteUserLoading &&
      inviteUserErrors &&
      inviteUserErrors.length === 0
    ) {
      fetchInvitationUsers();
    }
  }, [inviteUserLoading]);

  useEffect(() => {
    if (prevInviteAllLoading && !inviteAllLoading) {
      setCurrentPage(1);
      fetchInvitationUsers();
    }
  }, [inviteAllLoading]);

  useEffect(() => {
    if (prevDeleteInviteLoading && deleteInviteErrors.length === 0) {
      fetchInvitationUsers();
    }
  }, [deleteInviteLoading]);

  return (
    <>
      <InvitationTable
        users={invitedUsers}
        loading={invitedUsersLoading}
        inviteFiltersDirectory={inviteFiltersDirectory}
        onGetParsedUsersFromFile={onGetParsedUsersFromFile}
        onToggleInviteUserListModal={onToggleInviteUserListModal}
        inviteListModalOpen={inviteListModalOpen}
        invitationFilter={invitationFilter}
        onChangeInvitaionFilter={onChangeInvitaionFilter}
        onInviteUsersRequest={onInviteUsersRequest}
        inviteAllLoading={inviteAllLoading}
        onDeleteInvitedUser={onDeleteInvitedUser}
        invitationOrder={order}
        setInvitationOrder={setOrder}
        invitedSearchQuery={searchQuery}
        setInvitedSearchQuery={setSearchQuery}
        invitationAsc={invitationAsc}
        setInvitationAsc={setInvitationAsc}
      />
      <DashboardPaginator
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        membersLoading={invitedUsersLoading}
        setCurrentPage={setCurrentPage}
        usersTotalCount={invitedUsersTotalCount}
      />
    </>
  );
};
