import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { RootStore } from '../../../redux';
import { useDispatch, useSelector } from 'react-redux';

import {
  FormErrorMessage,
  FormControl,
  Flex,
  Box,
  Divider,
  Image,
  IconButton,
  Center,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import Select from 'react-select';

import { colors, fonts } from '../../../utils';

import { URL as BaseURL } from '../../../common/api/api.base';

import { Popup } from '../../../common/components/Popup';
import { SideTextEnum } from '../../../common/const/sideBar';
import FileUpload from '../../../common/components/FileUpload';
import DashboardPhotoModal from '../modals/DashboardPhotoModal';
import DashboardDeleteModal from '../modals/DashboardDeleteModal';
import { CustomButton, DatePicker, TextInput, Typography } from '../../../common/components';

import {
  AccountIcon,
  CallIcon,
  EmailIcon,
  LicenseIcon,
  LockIcon,
  ResizeIcon,
} from '../../../assets/icons';

import {
  AgentsType,
  LicenseModelType,
  LicensesType,
  ResetPasswordPayload,
  SaveIdLicensePayload,
  SetIdLicensePhotoPayload,
  UpdateUserProfilePayload,
} from '../../../types';

import { useDebounce } from '../../../common/hooks/useDebounce';

import { updateUserProfile } from '../../../redux/actions/licenses.actions';

interface IDashboardInfoFormProps {
  title: string;
  userId: number;
  onCloseModal: () => void;
  isIdLicenseVerified: boolean;
  idLicenseInfo: LicenseModelType;
  licensesInfo: Array<LicensesType>;
  onDeleteMember: (id: number) => void;
  onArchiveMember: (id: number) => void;
  onUnArchiveMember: (id: number) => void;
  onDeleteIdLicense: (id: number) => void;
  onConfirmIdLicense: (id: number) => void;
  onResetPassword: (data: ResetPasswordPayload) => void;
  onSetIdLicensePhoto: (data: SetIdLicensePhotoPayload) => void;
  onAgentSearch: (arg0: string) => void;
  agents: AgentsType[];
  agentsLoading: boolean;
}

export const DashboardInfoForm: React.FC<IDashboardInfoFormProps> = React.memo(
  ({
    idLicenseInfo,
    isIdLicenseVerified,
    onArchiveMember,
    onUnArchiveMember,
    userId,
    onDeleteMember,
    title,
    onSetIdLicensePhoto,
    onCloseModal,
    onConfirmIdLicense,
    onDeleteIdLicense,
    onResetPassword,
    licensesInfo,
    onAgentSearch,
    agents,
    agentsLoading,
  }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [checkBoxValue, setCheckBoxValue] = useState('No');
    const [passwordValue, setPasswordValue] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [idLicensePhoto, setIdLicensePhoto] = useState<File | null>(null);
    const [isSaveAsDraft, setIsSaveAsDraft] = useState(false);
    const [agentName, setAgentName] = useState('');
    const [timeoutEnableButton, setTimeoutEnableButton] = useState(true);
    const [defaultAgent, setDefaultAgent] = useState({
      value: idLicenseInfo?.advertiserId,
      label: idLicenseInfo?.agentFullName || 'Select',
      id: idLicenseInfo?.nrdsId,
      email: idLicenseInfo?.agentEmail,
    });

    const [agent, setAgent] = useState<{
      value: number | null;
      label: string;
      id: string;
      email: string;
    } | null>(null);
    const [agentsMapped, setAgentsMapped] = useState<
      {
        value: number;
        label: string;
        id: string;
        email: string;
      }[]
    >([]);

    const debouncedAgentName = useDebounce(agentName);

    const { buttonLoading } = useSelector((state: RootStore) => state.licenses);
    const { isLicenseEditable } = useSelector((state: RootStore) => state.licenses);

    const dispatch = useDispatch();
    const onUpdateUserProfile = (data: UpdateUserProfilePayload) =>
      dispatch(updateUserProfile(data));
    
      /*
    const isOneReLicenseAvailableForSubmit = licensesInfo?.some(
      lic =>
        //lic.licenseNumber &&
        // && lic.expirationDate
        lic.mlsId,
      // && lic.photo,
    );
    */

    const isOneReLicenseAvailableForSubmit = true;

    const {
      handleSubmit,
      control,
      reset,
      formState: { errors },
    } = useForm();

    useEffect(() => {
      reset({
        // idLicenseNumber: idLicenseInfo.licenseNumber || null,
        birthdate: idLicenseInfo.birthDate ? new Date(idLicenseInfo.birthDate) : null,
        dateCheckedIfIsApplicantInNar: new Date(),
        firstName: idLicenseInfo.firstName || '',
        phoneNumber: idLicenseInfo.phoneNumber || '',
        lastName: idLicenseInfo.lastName || '',
        email: idLicenseInfo.email || '',
      });
    }, [reset, idLicenseInfo]);

    useEffect(() => {
      if (idLicenseInfo?.advertiserId) {
        setDefaultAgent({
          value: idLicenseInfo?.advertiserId,
          label: idLicenseInfo?.agentFullName,
          id: idLicenseInfo?.nrdsId,
          email: idLicenseInfo?.email,
        });
      }
    }, [idLicenseInfo, idLicenseInfo?.agentFullName]);

    const licenseImage = idLicenseInfo.photo
      ? `${BaseURL}/api/storage/static-images/${idLicenseInfo.photo.itemHash}${idLicenseInfo.photo.extension}`
      : undefined;

    const handleConfirmIdLicense = async (data: UpdateUserProfilePayload) => {
      if (idLicensePhoto) {
        onSetIdLicensePhoto({ userId, photo: idLicensePhoto });
      }

      if (isSaveAsDraft) {
        const dateCheckedIfIsApplicantInNar =
          checkBoxValue === 'Yes'
            ? new Date(data.dateCheckedIfIsApplicantInNar).toISOString()
            : new Date().toISOString();

        const isApplicantListedInNarOfRealtors = checkBoxValue !== 'No';

        onUpdateUserProfile({
          ...data,
          userId,
          birthdate: data.birthdate ? new Date(data.birthdate).toISOString() : null,
          dateCheckedIfIsApplicantInNar,
          isApplicantListedInNarOfRealtors,
        });
      } else onConfirmIdLicense(userId);
    };

    useEffect(() => {
      if (isLicenseEditable) {
        setIsEditable(false);
      }
    }, [isLicenseEditable]);

    const handleResetPassword = () => {
      if (passwordValue.length < 6) {
        setIsPasswordValid(false);
      } else {
        setIsPasswordValid(true);
        onResetPassword({ UserId: userId, Password: passwordValue });
      }
    };
    const handleDeclineAll = () => {
      if (isIdLicenseVerified) {
        onDeleteIdLicense(idLicenseInfo.id);
      } else {
        onArchiveMember(userId);
        onCloseModal();
      }
    };
    const handleDeleteMember = () => {
      onDeleteMember(userId);
      onCloseModal();
    };
    const handleUnArchiveMember = () => {
      onUnArchiveMember(userId);
      onCloseModal();
    };
    const handleCancelUpdates = () => {
      reset(
        {
          birthdate: new Date(idLicenseInfo.birthDate),
          idLicenseNumber: idLicenseInfo.licenseNumber,
        },
        {
          keepDefaultValues: true,
        },
      );
      setIsEditable(false);
    };

    const isOverEighteen = (date: Date) => {
      if (!date) {
        return true;
      }
      const eighteenYearsAgo = dayjs().subtract(18, 'years');
      const birthday = dayjs(date);

      return eighteenYearsAgo.get('year') >= birthday.get('year');
    };

    useEffect(() => {
      if (agents && agents.length) {
        const agentsMap = agents.map(item => ({
          value: item.advertiserId || 0,
          label: `${item.fullName} (${item.email || 'no email'})` || '',
          id: item.nrdsId || '',
          email: item.email || '',
        }));
        setAgentsMapped(agentsMap);
      }
    }, [agents]);

    useEffect(() => {
      if (debouncedAgentName.length >= 3) {
        onAgentSearch(debouncedAgentName);
      }
    }, [debouncedAgentName]);

    useEffect(() => {
      setTimeout(() => setTimeoutEnableButton(false), 300);
    }, []);

    return (
      <form onSubmit={handleSubmit(handleConfirmIdLicense)}>
        <Flex mt="22px" justify="space-between" padding=" 0 30px 0 30px">
          <Box>
            <Controller
              name="firstName"
              control={control}
              defaultValue={idLicenseInfo.firstName || ''}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  isReadOnly={isIdLicenseVerified ? !isEditable : false}
                  onChange={onChange}
                  value={value}
                  label="First Name*"
                  icon={<AccountIcon />}
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue={idLicenseInfo.phoneNumber || ''}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  isReadOnly={isIdLicenseVerified ? !isEditable : false}
                  onChange={onChange}
                  value={value}
                  label="Phone Number*"
                  icon={<CallIcon />}
                />
              )}
            />
            <Flex mt="15px">
              <Box maxW="170px" mr="6">
                <Typography size={16}>Is Applicant listed in NAR List of Realtors?*</Typography>
                <RadioGroup
                  defaultValue="No"
                  mt="4"
                  mr="45px"
                  onChange={val => setCheckBoxValue(val)}
                >
                  <Stack spacing={10} direction="row">
                    <Radio
                      value="Yes"
                      size="lg"
                      disabled={isIdLicenseVerified ? !isEditable : false}
                    >
                      Yes
                    </Radio>
                    <Radio
                      value="No"
                      size="lg"
                      disabled={isIdLicenseVerified ? !isEditable : false}
                    >
                      No
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>
              {checkBoxValue === 'Yes' ? (
                <FormControl
                  isInvalid={errors.dateCheckedIfIsApplicantInNar}
                  isRequired={checkBoxValue === 'Yes'}
                  w="150px"
                >
                  <Box mb="1">
                    <Typography size={13} weight={300}>
                      Date checked
                    </Typography>
                  </Box>
                  <Controller
                    control={control}
                    name="dateCheckedIfIsApplicantInNar"
                    rules={{ required: checkBoxValue === 'Yes' }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        selected={value}
                        onChange={onChange}
                        disabled={isIdLicenseVerified ? !isEditable : false}
                      />
                    )}
                  />
                  <FormErrorMessage m="0px">
                    {errors.dateCheckedIfIsApplicantInNar && 'This is required'}
                  </FormErrorMessage>
                </FormControl>
              ) : null}
            </Flex>
          </Box>

          <Box>
            <Controller
              name="lastName"
              control={control}
              defaultValue={idLicenseInfo.lastName || ''}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  isReadOnly={isIdLicenseVerified ? !isEditable : false}
                  onChange={onChange}
                  value={value}
                  label="Last Name*"
                  icon={<AccountIcon />}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue={idLicenseInfo.email || ''}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  isReadOnly={isIdLicenseVerified ? !isEditable : false}
                  onChange={onChange}
                  value={value}
                  label="Email*"
                  icon={<EmailIcon />}
                />
              )}
            />
            <FormControl isInvalid={!isPasswordValid}>
              <TextInput
                label="Password*"
                placeholder="**********"
                subLabel="Reset User Password"
                icon={<LockIcon />}
                isPassword
                // onChange={(e) => setPasswordValue(e.target.value)}
                // onReset={handleResetPassword}
                isReadOnly
              />
              <FormErrorMessage m="0px">
                The Password must be at least 6 characters long
              </FormErrorMessage>
            </FormControl>
          </Box>
        </Flex>

        <Divider m="40px 0" />
        <Box mb="6">
          <Typography size={16}>Identity Confirmation Photo</Typography>
        </Box>
        <Flex justify="space-between">
          <Box pos="relative">
            <IconButton
              pos="absolute"
              borderRadius="14"
              bg="rgba(44, 51, 92, 0.9)"
              size="lg"
              right="0"
              aria-label="Resize"
              icon={<ResizeIcon />}
              colorScheme="blackAlpha"
              onClick={() => licenseImage && setIsOpenModal(true)}
            />
            {licenseImage ? (
              <Image
                borderRadius="14"
                objectFit="cover"
                w="350px"
                h="250px"
                src={idLicensePhoto ? URL.createObjectURL(idLicensePhoto) : licenseImage}
                alt="Driver license"
              />
            ) : idLicensePhoto ? (
              <Image
                borderRadius="14"
                objectFit="cover"
                w="350px"
                h="250px"
                src={URL.createObjectURL(idLicensePhoto)}
                alt="Driver license"
              />
            ) : (
              <Center borderRadius="14" bg={colors.ghost} h="250px" w="350px">
                <LicenseIcon />
              </Center>
            )}
          </Box>
          <Flex flexDir="column" justifyContent="space-between">
            <Box>
              <FormControl isInvalid={errors.birthdate} w="350px">
                <Box mb="1">
                  <Typography size={13} weight={300}>
                    Birth Date
                  </Typography>
                </Box>
                <Controller
                  control={control}
                  name="birthdate"
                  rules={{
                    // required: !isSaveAsDraft,
                    validate: {
                      checkYears: value =>
                        isOverEighteen(value) || 'You have to be at least 18 years old',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value}
                      maxDate={new Date()}
                      onChange={onChange}
                      disabled={isIdLicenseVerified ? !isEditable : false}
                    />
                  )}
                  defaultValue={null}
                />
                <FormErrorMessage m="0px">
                  {errors.birthdate && errors.birthdate.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Flex flexDir="column">
              <Typography size={13} weight={300}>
                Find Agent Account In Zootpro Database
              </Typography>
              <Box width="100%">
                <Select
                  options={agentsMapped}
                  autoFocus={false}
                  onInputChange={setAgentName}
                  onChange={e => {
                    setAgent(e);
                    setDefaultAgent({
                      value: e?.value || 0,
                      label: e?.label || '',
                      id: e?.id || '',
                      email: e?.email || '',
                    });
                  }}
                  isLoading={agentsLoading}
                  defaultValue={defaultAgent}
                  value={defaultAgent}
                  isDisabled={
                    title === SideTextEnum.UnVerifiedUsers ? timeoutEnableButton : !isEditable
                  }
                  styles={{
                    menu: styles => ({
                      ...styles,
                      backgroundColor: '#F6F7FB',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '16px',
                    }),
                    control: styles => ({
                      ...styles,
                      backgroundColor: '#F6F7FB',
                      borderWidth: 0,
                      height: '50px',
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '16px',
                      size: 'lg',
                      width: '345px',
                    }),
                    option: styles => ({
                      ...styles,
                      backgroundColor: '#F6F7FB',
                      color: 'black',
                      borderBottomWidth: 0.5,
                      '&:hover': {
                        opacity: 0.7,
                        backgroundColor: '#b0c4de',
                      },
                      '&:active': {
                        opacity: 1,
                        backgroundColor: '#b0c4de',
                      },
                    }),
                  }}
                />
              </Box>
            </Flex>
            <Flex alignItems="flex-end" justifyContent="space-between" height="30px">
              {isEditable || !isIdLicenseVerified ? (
                <FileUpload accept={'image/*'} onChange={setIdLicensePhoto}>
                  <Typography isUnderline family={fonts.roboto} size={15}>
                    Upload Photo
                  </Typography>
                </FileUpload>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
        <Box>
          <Divider m="40px 0" />
          {title === SideTextEnum.ArchivedUsers ? (
            <Flex justifyContent="flex-end">
              <Box w="235px" mr="24px">
                <CustomButton
                  background="transparent"
                  variant="outline"
                  color={colors.gray}
                  isSmall
                  onClick={handleDeleteMember}
                >
                  Delete permanently
                </CustomButton>
              </Box>
              <Box w="235px">
                <CustomButton isSmall onClick={handleUnArchiveMember}>
                  Restore
                </CustomButton>
              </Box>
            </Flex>
          ) : isIdLicenseVerified ? (
            isEditable ? (
              <Flex justifyContent="space-between">
                <Box w="235px">
                  <CustomButton isSmall onClick={() => setIsCancelModalOpen(true)}>
                    Cancel Updates
                  </CustomButton>
                </Box>
                <Box w="235px">
                  <CustomButton
                    isSmall
                    type="submit"
                    isLoading={buttonLoading}
                    onClick={() => setIsSaveAsDraft(true)}
                  >
                    Save updates
                  </CustomButton>
                </Box>
              </Flex>
            ) : (
              <Flex justify="flex-end">
                <Box w="235px">
                  <CustomButton isSmall onClick={() => setIsEditable(!isEditable)}>
                    Edit
                  </CustomButton>
                </Box>
              </Flex>
            )
          ) : (
            <Flex justify="space-between" mt="40px">
              <Box w="235px">
                <CustomButton isSmall onClick={() => setIsDeleteModalOpen(true)}>
                  Decline
                </CustomButton>
              </Box>
              <Box w="235px">
                <CustomButton
                  isLoading={buttonLoading}
                  isSmall
                  type="submit"
                  onClick={() => setIsSaveAsDraft(true)}
                >
                  Save As A draft
                </CustomButton>
              </Box>
              {isOneReLicenseAvailableForSubmit ? (
                <Box w="235px">
                  <CustomButton
                    isLoading={buttonLoading}
                    isSmall
                    type="submit"
                    onClick={() => setIsSaveAsDraft(false)}
                  >
                    Approve
                  </CustomButton>
                </Box>
              ) : (
                <Popup
                  text="Please fill all fileds on both tabs (General Info and Real Estate Licenses)
                to send all to verification. You can also save it as a draft and back later when
                you will available to finish filling the fields"
                >
                  <Box w="235px">
                    <CustomButton isDisabled isSmall>
                      Approve
                    </CustomButton>
                  </Box>
                </Popup>
              )}
            </Flex>
          )}
        </Box>
        {isOpenModal && (
          <DashboardPhotoModal
            imageSource={licenseImage}
            isOpen={isOpenModal}
            setIsOpen={setIsOpenModal}
          />
        )}
        <DashboardDeleteModal
          title="Are you sure you want decline this User?"
          text="This User will archived"
          isOpen={isDeleteModalOpen}
          onClick={handleDeclineAll}
          setIsModalOpen={setIsDeleteModalOpen}
        />
        <DashboardDeleteModal
          title="Are you sure you want cancel this edits? Latest edits will be removed"
          text="Latest edits will be removed."
          isOpen={isCancelModalOpen}
          onClick={handleCancelUpdates}
          setIsModalOpen={setIsCancelModalOpen}
        />
      </form>
    );
  },
);
