import {
  call, ForkEffect, put, takeEvery,
} from 'redux-saga/effects';
import {
  archiveMember,
  archiveMemberError,
  archiveMemberSuccess,
} from '../../actions/members.actions';
import ApiBase, { URL } from '../../../common/api/api.base';
import { IResponseWithoutValue } from '../../../types';

function* workerArchiveMember(action: ReturnType<typeof archiveMember>) {
  try {
    const result: IResponseWithoutValue = yield call(ApiBase.patch, `${URL}/api/admin/users/archive`, { userId: action.payload });
    yield console.log('archiveMember', action, result);
    if (result.success) {
      yield put(archiveMemberSuccess(action.payload));
    } else {
      yield put(archiveMemberError(result.errors));
    }
  } catch (error) {
    yield put(archiveMemberError(error));
    console.log(error);
  }
}

export default function* watchArchiveMemberSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(archiveMember.type, workerArchiveMember);
}
