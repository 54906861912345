import { createAction } from '@reduxjs/toolkit';

import {
  PayloadError,
  IResponseWithValue,
  StatesType,
  AgenciesType,
  GetAgenciesByStatePayload,
} from '../../types';

export const getStates = createAction('GET_STATES');
export const getStatesSuccess = createAction<IResponseWithValue<{ states: StatesType[] }>>('GET_STATES_SUCCESS');
export const getStatesError = createAction<PayloadError[]>('GET_STATES_ERROR');

export const getAgencies = createAction<GetAgenciesByStatePayload>('GET_AGENCIES');
export const getAgenciesSuccess = createAction<IResponseWithValue<{ agencies: AgenciesType[] }>>('GET_AGENCIES_SUCCESS');
export const getAgenciesError = createAction<PayloadError[]>('GET_AGENCIES_ERROR');

export const getStatesByAgency = createAction<number>('GET_STATES_BY_AGENCY');
export const getStatesByAgencySuccess = createAction<StatesType[]>('GET_STATES_BY_AGENCY_SUCCESS');
export const getStatesByAgencyError = createAction<PayloadError[]>('GET_STATES_BY_AGENCY_ERROR');

export const getInvitedFilterDirectoryRequest = createAction('GET_INVITED_FILTER_DIRECTORY_REQUEST');
export const getInvitedFilterDirectorySuccess = createAction<AgenciesType[]>('GET_INVITED_FILTER_DIRECTORY_SUCCESS');
export const getInvitedFilterDirectoryError = createAction<PayloadError[]>('GET_INVITED_FILTER_DIRECTORY_ERROR');
