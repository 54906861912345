import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';

import { RootStore } from '../../../../redux';
import SelectAgencyModal from '../SelectAgencyModal/SelectAgencyModal';
import {
  changeAgencyLogo,
  getAgency,
  removePartnerFromAgency,
  clearAgency,
} from '../../../../redux/actions/agency.actions';
import {
  AgencyType,
  ChangeAgencyLogoPayload,
  RemovePartnerFromAgencyPayload,
  EditOfficePayload,
  DeleteOfficePayload,
  AddOfficePayload,
} from '../../../../types';
import { URL as BaseURL } from '../../../../common/api/api.base';
import StatesModal from '../StatesModal';
import { getStatesByAgency } from '../../../../redux/actions/auxiliary.actions';
import { GeneralInfoTab, OfficesTab } from './tabs';
import { Typography } from '../../../../common/components';
import { colors, fonts } from '../../../../utils';
import {
  editOfficeRequest,
  getOfficesRequest,
  deleteOfficeRequest,
  addOfficeRequest,
} from '../../../../redux/actions/offices.actions';
import { OfficeAlertModal } from '../OfficeAlertModal';
import { OffcieLeaveAlertIcon } from '../../../../assets/icons';
import AdvertismentTab from './tabs/AdvertismentTab';

type IAgencyDetailsModalProps = {
  isOpen: boolean;
  agencyId: number | undefined;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  companyId: number;
};

const tabs = ['General Info', 'Offices', 'Advertisment'];

const AgencyDetailsModal: React.FC<IAgencyDetailsModalProps> = ({
  isOpen,
  setIsModalOpen,
  agencyId,
  companyId,
}) => {
  const [isSelectAgencyOpen, setIsSelectAgencyOpen] = useState(false);
  const [isStatesModalOpen, setIsStatesModalOpen] = useState(false);
  const [agencyLogo, setAgencyLogo] = useState<File | null>(null);
  const [agencyName, setAgencyName] = useState('Agency Name');
  const [selectedStates, setSelectedStates] = useState<number[]>([]);
  const [officesPage, setOfficesPage] = useState(1);
  const [officesSearchQuery, setOfficesSearchQuery] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [officeAlert, setOfficeAlert] = useState<boolean>(false);

  const agency: AgencyType = useSelector(
    (state: RootStore) => state.agency.agency
  );
  const { loading } = useSelector((state: RootStore) => state.agency);
  const { partnerLoading } = useSelector((state: RootStore) => state.agency);
  const {
    loading: officesLoading,
    items: offices,
    totalCount: officesTotalCount,
  } = useSelector((state: RootStore) => state.offices);
  const { loading: officeEditingLoading, officeId } = useSelector(
    (state: RootStore) => state.offices.editing
  );
  const [officesHandler, setOfficesHandler] = useState<any>(offices);

  const dispatch = useDispatch();
  const onGetAgency = (id: number) => dispatch(getAgency(id));
  const onChangeAgencyLogo = (data: ChangeAgencyLogoPayload) =>
    dispatch(changeAgencyLogo(data));
  const onRemovePartnerFromAgency = (data: RemovePartnerFromAgencyPayload) =>
    dispatch(removePartnerFromAgency(data));
  const onEditOffice = (data: EditOfficePayload) =>
    dispatch(editOfficeRequest(data));
  const onDeleteOffice = (payload: DeleteOfficePayload) =>
    dispatch(deleteOfficeRequest(payload));
  const onAddOffice = (payload: AddOfficePayload) =>
    dispatch(addOfficeRequest(payload));

  const handleRemovePartner = (PartnerId: number) => {
    if (agencyId) {
      onRemovePartnerFromAgency({ AgencyId: agencyId, PartnerId });
    }
  };

  const handleClickSeeStates = (value: number[]) => {
    setSelectedStates(value);
    setIsStatesModalOpen(true);
  };

  const onRemoveNewOffice = () => {
    const filteredOffices = officesHandler.filter((item: any) => item.id);
    setOfficesHandler(filteredOffices);
  };

  const newOfficeCheck = officesHandler.find(
    (item: any) => item.id === undefined
  );
  const handleModalClose = () => {
    if (newOfficeCheck) {
      setOfficeAlert(true);
    } else {
      setIsModalOpen(false);
      dispatch(clearAgency());
      setOfficesHandler([]);
    }
  };

  const defaultAgencyLogo =
    agencyId && agency.agency.logo
      ? `${BaseURL}/api/storage/static-images/${agency.agency.logo.itemHash}${agency.agency.logo.itemExtension}`
      : undefined;

  useEffect(() => {
    if (agencyLogo !== null && agencyId) {
      onChangeAgencyLogo({ id: agencyId, logo: agencyLogo });
    }
  }, [agencyId, agencyLogo]);

  useEffect(() => {
    if (agencyId && isOpen) {
      onGetAgency(agencyId);
      dispatch(getStatesByAgency(agencyId));
    }
    if (!isOpen) {
      setTabIndex(0);
    }
  }, [agencyId, isOpen]);

  useEffect(() => {
    if (agencyId) {
      setAgencyName(agency.agency.name);
    } else {
      setAgencyName('Agency Name');
    }
  }, [agencyId, agency]);

  useEffect(() => {
    if (tabIndex === 1 && agencyId) {
      dispatch(
        getOfficesRequest({
          AgencyId: agencyId,
          PageNumber: officesPage,
          PageSize: 50,
          Search: officesSearchQuery,
        })
      );
    }
  }, [tabIndex, officesSearchQuery, officesPage]);

  useEffect(() => {
    setOfficesHandler(offices);
  }, [offices]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        autoFocus={false}
        size={tabIndex === 2 ? 'full' : '4xl'}
      >
        <ModalOverlay />

        <ModalContent p="20px 70px">
          <Tabs index={tabIndex} onChange={setTabIndex}>
            <ModalHeader
              alignItems="center"
              justifyContent="space-between"
              d="flex"
              my="20px"
            >
              <Typography
                weight={600}
                size={20}
                noOfLines={2}
                textProps={{ maxW: '45%' }}
              >
                {agencyName}
              </Typography>
              {agencyId && !loading ? (
                <TabList border="none">
                  {tabs.map((elem) => (
                    <Tab
                      key={elem}
                      fontSize="13px"
                      color={colors.primary}
                      _selected={{ background: colors.primary, color: 'white' }}
                      border="none"
                      borderRadius="5px"
                      fontFamily={fonts.roboto}
                    >
                      {elem}
                    </Tab>
                  ))}
                </TabList>
              ) : null}
              <ModalCloseButton color={colors.gray} />
            </ModalHeader>

            <TabPanels>
              <TabPanel p="0">
                <GeneralInfoTab
                  agency={agency}
                  agencyLogo={agencyLogo}
                  handleClickSeeStates={handleClickSeeStates}
                  handleRemovePartner={handleRemovePartner}
                  loading={loading}
                  partnerLoading={partnerLoading}
                  setAgencyLogo={setAgencyLogo}
                  setAgencyName={setAgencyName}
                  setIsModalOpen={(value) => {
                    setIsModalOpen(value);
                    dispatch(clearAgency());
                  }}
                  setIsSelectAgencyOpen={setIsSelectAgencyOpen}
                  agencyId={agencyId}
                  defaultAgencyLogo={defaultAgencyLogo}
                  selectedStates={selectedStates}
                  companyId={companyId}
                />
              </TabPanel>
              <TabPanel p="0">
                <OfficesTab
                  onEditOffice={onEditOffice}
                  page={officesPage}
                  setPage={setOfficesPage}
                  searchQuery={officesSearchQuery}
                  setQuery={setOfficesSearchQuery}
                  loading={officesLoading}
                  items={officesHandler}
                  totalCount={officesTotalCount}
                  editingLoading={officeEditingLoading}
                  officeId={officeId}
                  onDeleteOffice={onDeleteOffice}
                  agencyId={agencyId}
                  setOfficesHandler={setOfficesHandler}
                  onAddOffice={onAddOffice}
                  newOfficeCheck={newOfficeCheck}
                />
              </TabPanel>
              <TabPanel p="0">
                <AdvertismentTab agencyId={agencyId} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalContent>
      </Modal>
      {isSelectAgencyOpen && agencyId ? (
        <SelectAgencyModal
          agencyId={agencyId}
          isOpen={isSelectAgencyOpen}
          setIsModalOpen={(value) => {
            setIsSelectAgencyOpen(value);
          }}
        />
      ) : null}
      <StatesModal
        isOpen={isStatesModalOpen}
        onClose={() => {
          setIsStatesModalOpen(false);
        }}
        agencyId={agencyId || 0}
        selectedStates={selectedStates}
        setSelectedStates={setSelectedStates}
      />
      <OfficeAlertModal
        isOpen={officeAlert}
        setIsModalOpen={setOfficeAlert}
        actionFunction={() => {
          setIsModalOpen(false);
          dispatch(clearAgency());
          setOfficeAlert(false);
          onRemoveNewOffice();
          setOfficesHandler([]);
        }}
        title="Are you sure you want to leave?"
        subTitle="The created office does not contain the required information. It will be deleted."
        icon={<OffcieLeaveAlertIcon />}
      />
    </>
  );
};

export default AgencyDetailsModal;
