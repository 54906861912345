import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InnerAgenciesTable, DashboardPaginator } from '../components';
import { getAgenciesWithEmails } from '../../../redux/actions/agency.actions';
import { RootStore } from '../../../redux';

const PAGE_SIZE = 50;

export const InnerAgencies: React.FC<any> = ({ match }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [agencyCheckedStates, setAgencyCheckedStates] = useState<number[]>([]);
  const [orderType, setOrderType] = useState(true);
  const [orderField, setOrderField] = useState<string | undefined>();

  const { agenciesWithEmails, agenciesTotalCount, agenciesWithEmailsLoading } =
    useSelector((state: RootStore) => state.agency);
  const { states } = useSelector((state: RootStore) => state.auxiliary);

  useEffect(() => {
    dispatch(
      getAgenciesWithEmails({
        PageNumber: currentPage,
        PageSize: PAGE_SIZE,
        States: agencyCheckedStates,
        OrderType: orderType,
        OrderField: orderField,
        SearchQuery: searchQuery,
        companyId: match.params.companyId,
      })
    );
  }, [currentPage, agencyCheckedStates, orderType, orderField, searchQuery]);
  return (
    <>
      <InnerAgenciesTable
        states={states}
        auxiliaryLoading={agenciesWithEmailsLoading}
        agenciesWithEmails={agenciesWithEmails}
        agencyCheckedStates={agencyCheckedStates}
        setAgencyCheckedStates={setAgencyCheckedStates}
        agencyOrderType={orderType}
        setAgencyOrderType={setOrderType}
        setAgencyOrderField={setOrderField}
        agencySearchQuery={searchQuery}
        setAgencySearchQuery={setSearchQuery}
        companyName={match.params.companyName}
        companyId={match.params.companyId}
      />
      <DashboardPaginator
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        membersLoading={agenciesWithEmailsLoading}
        usersTotalCount={agenciesTotalCount}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};
